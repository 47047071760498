import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
window.am4core = am4core;
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";


 window.generate_pie_chart = function(chart_data,elemId){

     am4core.ready(function () {

         // Themes begin
         am4core.useTheme(am4themes_dataviz);
         am4core.useTheme(am4themes_animated);
         // Themes end

         // Create chart
         let chart = am4core.create(elemId, am4charts.PieChart);
         chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

         chart.data = chart_data;

         let series = chart.series.push(new am4charts.PieSeries());
         series.dataFields.value = 'value';
         series.dataFields.radiusValue = 'value';
         series.dataFields.category = 'country';
         series.slices.template.cornerRadius = 6;
         series.colors.step = 3;

         series.hiddenState.properties.endAngle = -90;

         chart.legend = new am4charts.Legend();

     }); // end am4core.ready()
 }

window.generate_column_chart_image = function(chart_data,elemId,series_details){


// Themes begin
        am4core.useTheme(am4themes_animated);
// Themes end

        var chart = am4core.create(elemId, am4charts.XYChart);
        chart.padding(40, 40, 40, 40);

        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "BI_TITLE";
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;

        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;

        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "BI_TITLE";
        series.dataFields.valueX = "PERCENTAGE_SCORE";
        series.tooltipText = "{valueX.value}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusBottomRight = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;

        var labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.horizontalCenter = "left";
        labelBullet.label.dx = 10;
        labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.0as')}";
        labelBullet.locationX = 1;

// as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
        series.columns.template.adapter.add("fill", function(fill, target){
            return chart.colors.getIndex(target.dataItem.index);
        });

        categoryAxis.sortBySeries = series;
        chart.data = chart_data;
        chart.exporting.getImage("png").then(function(imgData) {
            console.log('adding graph image')
            console.log(imgData)
            $("#chart_data").attr('src',imgData)
        });
        return chart;




}
window.generate_column_chart = function(chart_data,elemId,series_details,if_ajax,aj_url,agid){
    am4core.ready(function() {

// Themes begin
        am4core.useTheme(am4themes_animated);
// Themes end

        var chart = am4core.create(elemId, am4charts.XYChart);
        chart.padding(40, 40, 40, 40);

        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "BI_TITLE";
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;

        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 100;

        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "BI_TITLE";
        series.dataFields.valueX = "PERCENTAGE_SCORE";
        series.tooltipText = "{valueX.value}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusBottomRight = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;

        var labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.horizontalCenter = "left";
        labelBullet.label.dx = 10;
        labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.0as')}";
        labelBullet.locationX = 1;

// as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
        series.columns.template.adapter.add("fill", function(fill, target){
            return chart.colors.getIndex(target.dataItem.index);
        });

        categoryAxis.sortBySeries = series;
        chart.data = chart_data;
        if(if_ajax) {
            setTimeout(function(){
                let options = chart.exporting.getFormatOptions("png");

                // options.minWidth = 500;
                // options.minHeight = 300;
                options.quality = 1
                options.scale=2

                chart.exporting.getImage("png", options).then(function (imgData) {
                    console.log('adding graph image')
                    $.ajax({
                        type:"POST",
                        url: aj_url,
                        dataType:"JSON",
                        data:{
                            authenticity_token: $('[name="csrf-token"]')[0].content,
                            attempt_group_id:agid,
                            svg_data:imgData
                        },
                        success:function(data){
                            $(".download-report").show()
                        }


                    })


                });
            },5000)

        }
        return chart;



    }); // end am4core.ready()
}
 window.generate_bar_chart = function(chart_data,elemId,series_details){

     am4core.ready(function () {

         // Themes begin
         am4core.useTheme(am4themes_animated);
         // Themes end

         var chart = am4core.create(elemId, am4charts.XYChart)
         chart.colors.step = 2;


         chart.legend = new am4charts.Legend()
         chart.legend.position = 'top'
         chart.legend.paddingBottom = 20
         chart.legend.labels.template.maxWidth = 95

         var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
         xAxis.dataFields.category = 'category'
         xAxis.renderer.cellStartLocation = 0.1
         xAxis.renderer.cellEndLocation = 0.9
         xAxis.renderer.grid.template.location = 0;

         var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
         yAxis.min = 0;
         // yAxis.max = 100;
         yAxis.title.text = "% Score";
         yAxis.renderer.labels.template.disabled = false
         // yAxis.disabled = true;

         function createSeries(value, name) {
             var series = chart.series.push(new am4charts.ColumnSeries())
             series.dataFields.valueY = value
             series.dataFields.categoryX = 'category'
             series.name = name

             series.events.on('hidden', arrangeColumns);
             series.events.on('shown', arrangeColumns);

             var bullet = series.bullets.push(new am4charts.LabelBullet())
             bullet.interactionsEnabled = false
             bullet.dy = 30;
             bullet.label.text = '{valueY}%'
             bullet.label.fill = am4core.color('#ffffff')

             return series;
         }


         chart.data = chart_data
         for (let i = 0; i < series_details.length; i++) {
             createSeries(series_details[i]['value'], series_details[i]['title']);
         }

         function arrangeColumns() {

             var series = chart.series.getIndex(0);

             var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
             if (series.dataItems.length > 1) {
                 var x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX');
                 var x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX');
                 var delta = ((x1 - x0) / chart.series.length) * w;
                 if (am4core.isNumber(delta)) {
                     var middle = chart.series.length / 2;

                     var newIndex = 0;
                     chart.series.each(function (series) {
                         if (!series.isHidden && !series.isHiding) {
                             series.dummyData = newIndex;
                             newIndex++;
                         }
                         else {
                             series.dummyData = chart.series.indexOf(series);
                         }
                     })
                     var visibleCount = newIndex;
                     var newMiddle = visibleCount / 2;

                     chart.series.each(function (series) {
                         var trueIndex = chart.series.indexOf(series);
                         var newIndex = series.dummyData;

                         var dx = (newIndex - trueIndex + middle - newMiddle) * delta

                         series.animate({ property: 'dx', to: dx }, series.interpolationDuration, series.interpolationEasing);
                         series.bulletsContainer.animate({ property: 'dx', to: dx }, series.interpolationDuration, series.interpolationEasing);
                     })
                 }
             }
         }

     }); // end am4core.ready()

 }

 window.generate_pic_chart = function(elemId,act_val){

         am4core.ready(function() {

// Themes begin
         am4core.useTheme(am4themes_animated);
// Themes end

         var capacity = 100;
         var value = act_val;
         var circleSize = 1;

         var component = am4core.create(elemId, am4core.Container)
         component.width = am4core.percent(100);
         component.height = am4core.percent(100);

         var chartContainer = component.createChild(am4core.Container);
         chartContainer.x = am4core.percent(50)
         chartContainer.y = am4core.percent(50)

         var circle = chartContainer.createChild(am4core.Circle);
         circle.fill = am4core.color("#ffffff");
         circle.borderColor="#000000"

         var circleMask = chartContainer.createChild(am4core.Circle);

         var waves = chartContainer.createChild(am4core.WavedRectangle);
         waves.fill = am4core.color("#FFC700");
         waves.mask = circleMask;
         waves.horizontalCenter = "middle";
         waves.waveHeight = 10;
         waves.waveLength = 30;
         waves.y = 500;
         circleMask.y = -500;

         component.events.on("maxsizechanged", function(){
         var smallerSize = Math.min(component.pixelWidth, component.pixelHeight);
         var radius = smallerSize * circleSize / 2;

         circle.radius = radius;
         circleMask.radius = radius;

         waves.height = smallerSize;
         waves.width = Math.max(component.pixelWidth, component.pixelHeight);

         //capacityLabel.y = radius;

         var labelRadius = radius + 20

         capacityLabel.path = am4core.path.moveTo({x:-labelRadius, y:0}) + am4core.path.arcToPoint({x:labelRadius, y:0}, labelRadius, labelRadius);
         capacityLabel.locationOnPath = 0.5;

         setValue(value);
     })


         function setValue(value){
         var y = - circle.radius - waves.waveHeight + (1 - value / capacity) * circle.pixelRadius * 2;
         waves.animate([{property:"y", to:y}, {property:"waveHeight", to:10, from:15}, {property:"x", from:-50, to:0}], 5000, am4core.ease.elasticOut);
         circleMask.animate([{property:"y", to:-y},{property:"x", from:50, to:0}], 5000, am4core.ease.elasticOut);
     }


         var label = chartContainer.createChild(am4core.Label)
         var formattedValue = component.numberFormatter.format(value, "#.#a");
         formattedValue = formattedValue.toUpperCase();

         label.text = formattedValue ;
         label.fill = am4core.color("#000");
         label.fontSize = 30;
         label.horizontalCenter = "middle";


         var capacityLabel = chartContainer.createChild(am4core.Label)

         var formattedCapacity = component.numberFormatter.format(capacity, "#.#a").toUpperCase();;

         capacityLabel.text = " " + formattedCapacity ;
         capacityLabel.fill = am4core.color("#34a4eb");
         capacityLabel.fontSize = 20;
         capacityLabel.textAlign = "middle";
         capacityLabel.padding(0,0,0,0);

     }); // end am4core.ready()


 }



